import React, { useEffect, useRef, ReactNode } from 'react';

interface AccessibleDialogProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  ariaLabel: string;
  children: ReactNode;
}

export const AccessibleDialog = ({
  onConfirm,
  onCancel,
  ariaLabel,
  children,
}: AccessibleDialogProps) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (onConfirm && onCancel && dialogRef.current) {
      dialogRef.current.focus();
    }
  }, [onConfirm, onCancel]);

  if (!onConfirm || !onCancel) return null;

  return (
    <div
      aria-modal="true"
      aria-live="assertive"
      role="alertdialog"
      aria-label={ariaLabel}
      ref={dialogRef}
      tabIndex={-1}
    >
      {children}
    </div>
  );
};
