import * as Modal from './ModalContainer';

export { Header, Title } from '../Info/info.styles';
export { ModalContainer } from './ModalContainer';
export { Modal };
export { default as Body } from './Body';
export * from './ModalContainer';
export * from './Buttons';
export * from './Iframe';
export * from './AccessibleDialog';
