import React from 'react';
import { ModalContainer } from '../components/ModalContainer';
import { Content, FooterWrapper, Header } from './styles';
import Message from 'src/components/Modals/SessionTimeoutDialog/Message';
import { t } from 'i18next';
import { TitleContainer } from '../SessionTimeoutDialog/styles';
import Footer from 'src/components/Modals/SessionTimeoutDialog/Footer';
import { AccessibleDialog } from '../components';

interface ISessionTimeoutDialog {
  onConfirm?: () => void;
  onCancel?: () => void;
  onCountdownComplete: () => void;
  timeout: number;
}

const SessionTimeoutDialog = ({
  onCancel,
  onConfirm,
  timeout,
  onCountdownComplete,
}: ISessionTimeoutDialog) => {
  return (
    <AccessibleDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      ariaLabel={t('SessionExpirationMessage')}
    >
      <ModalContainer aria-modal="true" aria-live="off" width={360}>
        <Header>
          <TitleContainer>{t('SessionTimeout')}</TitleContainer>
        </Header>
        <Content>
          <Message
            timeout={timeout}
            onCountdownComplete={onCountdownComplete}
          />
        </Content>
        <FooterWrapper>
          <Footer onConfirm={onConfirm} onCancel={onCancel} />
        </FooterWrapper>
      </ModalContainer>
    </AccessibleDialog>
  );
};

export default SessionTimeoutDialog;
