import React, { FC, useEffect, useRef } from 'react';
import { Background } from '../../../Background';
import { Modal } from './ModalContainer.styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IModal } from './interfaces';

export const ModalContainer: FC<IModal> = ({ children, ...rest }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    disableBodyScroll(modalElement);

    return () => enableBodyScroll(modalElement);
  }, []);

  return (
    <Background tabIndex={1} color="dark">
      <Modal
        aria-live={'assertive'}
        role="alertdialog"
        tabIndex={1}
        ref={modalRef}
        {...rest}
      >
        {children}
      </Modal>
    </Background>
  );
};
