import React, { ComponentProps } from 'react';
import { DialogModal } from './DialogModal';
import { t } from 'i18next';
import { AccessibleDialog } from 'src/components/Modals/components';

type Props = ComponentProps<typeof DialogModal>;

const RejectDialog = ({
  onCancel,
  onConfirm,
  title = t('Confirmation'),
}: Props) => {
  const message: string = t(
    'IfYouRejectTheAgreementItWillBeDiscardedAndStoredInTheArchive'
  );
  const warning: string = t('AreYouSureYouWantToRejectTheAgreement');

  const dialogProps = {
    title,
    message,
    warning,
    onCancel,
    onConfirm,
  };

  return (
    <AccessibleDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      ariaLabel={t('AreYouSureYouWantToRejectTheAgreement')}
    >
      <DialogModal {...dialogProps} data-testid="data-testid" />
    </AccessibleDialog>
  );
};

export default RejectDialog;
