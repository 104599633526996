import React, { ComponentProps } from 'react';
import { Modal } from '../Modal';

interface Props extends ComponentProps<typeof Modal> {
  message?: string;
  warning?: string;
}

export const DialogModal = ({
  title,
  onCancel,
  onConfirm,
  message,
  warning,
}: Props) => {
  return (
    <Modal title={title} onCancel={onCancel} onConfirm={onConfirm}>
      {message && <p aria-label={message}>{message}</p>}
      {warning && <p aria-label={warning}>{warning}</p>}
    </Modal>
  );
};
