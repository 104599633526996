import { mediaMaxWidth } from 'src/styles/layout';
import styled from 'styled-components';

const textOverflowEllipsis = `
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Title = styled.h1`
  font-size: 2em;
  text-align: center;
  padding: 0.6em 1em;
  margin: 0;
  color: ${({ theme }) => theme.pallete.headers.main};
  font-family: ${({ theme }) => theme.typography.header.fontFamily};
  ${textOverflowEllipsis}
  ${mediaMaxWidth.tabletSmall(`
    font-size: 1.4em;
    padding: 0.75em;
  `)};
`;

export const Subtitle = styled.h3`
  font-size: 1em;
  padding: 0.3em 0;
  opacity: 0.7;
  ${textOverflowEllipsis}
`;
